import React, { useEffect, useState } from "react";
import { contentData } from "../utils/readMoreContent";
import { useLocation } from "react-router";
import Header from "./header";
import Footer from "./footer";

const ReadMore = () => {
  const [currentSection, setCurrentSection] = useState("");
  const location = useLocation();
  const { articleNo } = location.state || {};
  const [getArticleData, setGetArticleData] = useState({});

  useEffect(() => {
    if (articleNo) {
      switch (articleNo) {
        case "article1":
          setGetArticleData(contentData.article1);
          setCurrentSection(Object.keys(contentData.article1)[0]); // Set default section
          break;
        case "article2":
          setGetArticleData(contentData.article2);
          setCurrentSection(Object.keys(contentData.article2)[0]); // Set default section
          break;
        case "article3":
          setGetArticleData(contentData.article3);
          setCurrentSection(Object.keys(contentData.article3)[0]); // Set default section
          break;
        case "article4":
          setGetArticleData(contentData.article4);
          setCurrentSection(Object.keys(contentData.article4)[0]); // Set default section
          break;

        case "our_work_article1":
          setGetArticleData(contentData.our_work_article1);
          setCurrentSection(Object.keys(contentData.our_work_article1)[0]); // Set default section
          break;
        case "our_work_article2":
          setGetArticleData(contentData.our_work_article2);
          setCurrentSection(Object.keys(contentData.our_work_article2)[0]); // Set default section
          break;
        case "our_work_article3":
          setGetArticleData(contentData.our_work_article3);
          setCurrentSection(Object.keys(contentData.our_work_article3)[0]); // Set default section
          break;
        case "our_work_article4":
          setGetArticleData(contentData.our_work_article4);
          setCurrentSection(Object.keys(contentData.our_work_article4)[0]); // Set default section
          break;
        default:
          setGetArticleData({});
      }
    }
  }, [articleNo]);

  return (
    <>
      <Header />
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6",marginBottom:"50px" }}>
        {/* Header Section */}
        <div style={{ backgroundColor: "#ffeedd", padding: "20px 40px" }}>
          <h1 style={{ textAlign: "center", color: "#333" }}>
            Lorem Ipsum is simply dummy text
          </h1>
        </div>

        {/* Main Content Section */}
        <div style={{ display: "flex", padding: "20px 40px" }}>
          {/* Table of Contents */}
          <div
            style={{
              flex: "1",
              paddingRight: "20px",
              borderRight: "1px solid #ccc",
            }}
          >
            <h3 style={{ color: "#333" }}>TABLE OF CONTENTS</h3>
            <ul style={{ listStyleType: "none", padding: "0" }}>
              {Object.keys(getArticleData)?.map((section) => (
                <li
                  key={section}
                  onClick={() => setCurrentSection(section)}
                  style={{
                    margin: "10px 0",
                    cursor: "pointer",
                    color: currentSection === section ? "#212121" : "#000",
                    fontWeight: currentSection === section ? "bold" : "normal",
                    backgroundColor:currentSection === section ? "#ffeedd" : "",
                    padding:"10px"
                  }}
                >
                  {section} 
                </li>
              ))}
            </ul>
          </div>

          {/* Article Content */}
          <div style={{ flex: "3", paddingLeft: "20px" }}>
            {getArticleData[currentSection]}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReadMore;
