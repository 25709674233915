import Utils from "../../utils/reduxUtils";

const {
    GET_CURRENT_SESSION,
    GET_PREVIOUS_QUESTIONS,
    LOADING_SESSION,
    SESSION_QUESTION_LOADING,
    RESET_CHATBOT_SESSION,
    RESET_CURRENT_QUESTION,
    GET_DRAFTED_CURRENT_QUESTION,
    VACANCYNUMBER
} = Utils.ActionName;

const initialState = {
    questionLoading: false,
    sessionQuestionLoading:false,
    session:[],
    draftedCurrentQuestion:{},
    vacancyNumber:0
};

export const chatbotReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_SESSION:
            console.log(action.payload,"action.payload");
           return { ...state, ...action.payload, questionLoading: false };
        case GET_DRAFTED_CURRENT_QUESTION:
            console.log(action.payload,"action.payload");
            
            return {...state,draftedCurrentQuestion:action.payload, currentQuestion:action.payload}   
        // case VACANCYNUMBER:
        //     return {...state,vacancyNumber:state.vacancyNumber+ action.payload};    
        case GET_PREVIOUS_QUESTIONS:
            return { ...state, ...action.payload, questionLoading: false };
        case LOADING_SESSION:
            return { ...state, questionLoading: action.payload };
        case SESSION_QUESTION_LOADING:
                return { ...state, sessionQuestionLoading: action.payload };
        case RESET_CHATBOT_SESSION:
            return {...initialState };
        case RESET_CURRENT_QUESTION:
            console.log('state55',state);
            return {}; 
        default:
            return state;
    }
};