import Utils from "../../utils/reduxUtils";

const {
    GET_DASHBOARD_DATA,
    RESET_DASHBOARD_DATA,
    DASHBOARD_DATA_LOADING,
    VACANCYNUMBER
} = Utils.ActionName;

const initialState = {loading:false,GeneratedVacancyNumber:0};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return { ...state, ...action.payload, loading:false };
        case VACANCYNUMBER:
            return {...state,GeneratedVacancyNumber:state.GeneratedVacancyNumber+action.payload}    
        case RESET_DASHBOARD_DATA:
            return { ...initialState };
        case  DASHBOARD_DATA_LOADING:
            return {...state,loading:true}   
        default:
            return state;
    }
};