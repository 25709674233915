import Utils from "../../utils/reduxUtils";

export const createChatbotSession = (companyDetails) => {
    console.log("entered chatbot")
    return (dispatch) => {
        dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: true });
        return new Promise((resolve, reject) => {
            Utils.api.postApiCall(
                Utils.EndPoint.createChatbotSession,
                companyDetails,
                (resData) => { // Success callback
                   
                    if (resData?.status === 200) {
                        dispatch({ type: Utils.ActionName.GET_CURRENT_SESSION, payload: resData.data });
                        dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                        
                        resolve(resData); 
                    } else {
                        dispatch({ type: Utils.ActionName.RESET_CHATBOT_SESSION });
                        dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                        reject(resData);
                    }
                },
                (error) => { 
                     
                    dispatch({ type: Utils.ActionName.RESET_CHATBOT_SESSION });
                    dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                    reject(error);
                },
                false, 
                "" 
            );
        });
    };
};


export const getNextQuestion = (currentQuestion,sessionId) => {
    console.log('sessionId',currentQuestion,sessionId);
    return (dispatch) => {
        dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: true });
        return new Promise((resolve, reject) => {
            Utils.api.postApiCall(
                Utils.EndPoint.getNextQuestion(sessionId),
                currentQuestion,
                (resData) => {
                    dispatch({
                        type: Utils.ActionName.GET_CURRENT_SESSION,
                        payload: resData.data,
                    });
                    dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                    resolve(resData);
                },
                (error) => {
                    dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                    reject(error);
                }
            );
        });
    };
}


export const getPrevQuestion = (sessionId) => {
    return (dispatch) => {
        dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: true });
        return new Promise((resolve, reject) => {
            Utils.api.getApiCall(
                Utils.EndPoint.getPreviousQuestion(sessionId),
                
                (resData) => {
                    console.log("res654Data",resData);
                    dispatch({
                        type: Utils.ActionName.GET_CURRENT_SESSION,
                        payload: resData.data,
                    });
                    dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                    resolve(resData);
                },
                (error) => {
                    dispatch({ type: Utils.ActionName.LOADING_SESSION, payload: false });
                    reject(error);
                }
            );
        });
    };
}


export const moveToCurrentQuestionFromDraft = (id) => {
    return (dispatch) => {
        dispatch({ type: Utils.ActionName.SESSION_QUESTION_LOADING, payload: true });
        return new Promise((resolve, reject) => {
            Utils.api.getApiCall(
                Utils.EndPoint.getChatbotSession(id),
                (resData) => { // Success callback
                    if (resData?.status === 200) {
                        // dispatch({ type: Utils.ActionName.SHARE_VACANCY_TEXT, payload: resData.data });
                        // dispatch({ type: Utils.ActionName.GOTO_VACANCYTEXT_FROM_DASHBOARD, payload: resData.data });
                        console.log("resDa55ta",resData.data);
                        const currentQuestionKey = resData.data.currentQuestion;
                        let draftedCurrentQuestion = resData.data.session.find(
                          (ele) => ele.key === currentQuestionKey
                        );
                        if(draftedCurrentQuestion===undefined){
                            draftedCurrentQuestion=resData.data?.session[0];
                        }
                        console.log("draftedCurre51ntQuestion",draftedCurrentQuestion);
                        dispatch({
                            type: Utils.ActionName.GET_CURRENT_SESSION,
                            payload: {currentQuestion: draftedCurrentQuestion},
                        });
                        //  dispatch({
                        //     type: Utils.ActionName.GET_DRAFTED_CURRENT_QUESTION,
                        //     payload: resData.data,
                        // });
                        dispatch({ type: Utils.ActionName.SESSION_QUESTION_LOADING, payload: false });
                        resolve(resData); 
                    } else {
    
                        reject(resData);
                    }
                },
                (error) => { 
                    reject(error); 
                },
                false, 
                "" 
            );
        });
    };
  };
  

