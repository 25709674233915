import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { styled } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../redux/action/profileAction";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MailOutline from "@mui/icons-material/MailOutline";
import visa from "../../assets/img/svg/visa.svg";
import mastercard from "../../assets/img/svg/mastercard.svg";
import "../payment/payment.scss";
import { useParams } from "react-router";
import {
  generatePaymentIntent,
  getSubscriptionById,
} from "../../redux/action/subscriptionAction";
import SubscriptionCard from "../../components/subscriptionCard";
// import PaymentForm from './checkoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckOutForm2";
import GooglePayCheckoutForm from "./GpayCheckoutForm";
import ApplePayCheckoutForm from "./ApplePayCheckoutForm";

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  display: "flex",
  justifyContent: "flex-start",
});

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  padding: theme.spacing(1),
  borderRadius: "30px",
  fontStyle: "Montserrat",
  fontWeight: "600",
  flex: 1,
  "&.Mui-selected": {
    // backgroundColor: '#F89B53',
    boxShadow: "-0.09px 3.63px 35.53px -17.81px #757576",
    borderRadius: "16px 16px 0px 0px !important",
    color: "#F89B53",
    maxWidth: "fit-content",
    padding: "10px 26px",
  },
  "&:not(.Mui-selected)": {
    color: "#7C7C7C",
    fontWeight: "500",
    maxWidth: "fit-content",
    padding: "10px 26px",
  },
}));
const stripePromise = loadStripe(
  "pk_test_51PpRoNP5vB9csVWC4ANMF5wzOF8o6z78lUQ0Nvv1F7MKJfzSzuccuUz8A2kEwlvea1gczX04bvUHsDxumAkLJqbS00DR3EREa1"
);
const Payment = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state?.authenticationReducer
  );
  console.log("sdfdsfdsf",userData)
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    dispatch(getProfileInfo(userData?._id));
  }, [userData?._id]);
  useEffect(() => {
    if (params?.id) {
      dispatch(getSubscriptionById(params?.id));
      const user = localStorage.getItem("userData");
      dispatch(generatePaymentIntent(params?.id, userData?._id));
    }
  }, [params]);
  const subscription = useSelector(
    (state) => state?.subscriptions?.subscription
  );
  console.log(subscription, "subscription");
  const paymentIntent = useSelector(
    (state) => state?.subscriptions?.paymentIntent?.data?.paymentIntent
  );
  // Wait until the paymentIntent is available
  if (!paymentIntent?.client_secret) {
    return <div>Loading payment details...</div>;
  }

  return (
    <>
      <Header />
      <Box>
        <Grid
          container
          className="mt-4"
          sx={{ padding: "21px 39px 21px 42px" }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="px-3">
            <Box
              className="SubscriptionPlan"
              sx={{ boxShadow: "none !important" }}
            >
              {subscription && (
                <SubscriptionCard
                  item={subscription}
                  hideBtn={true}
                ></SubscriptionCard>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{ px: 6, py: 3, borderRadius: 5 }}>
                  <Typography variant="h3" className="fs-22 fw-600">
                    Payment
                  </Typography>
                  <Divider
                    className="mt-15 mb-20"
                    sx={{ opacity: 1 }}
                    aria-hidden="true"
                  />
                  <Box className="d-flex justify-content-between">
                    <Typography variant="h3" className="fs-14 fw-400">
                      Your Plan Subscription (Inc. {subscription?.credits}{" "}
                      Credit)
                    </Typography>
                    <Typography variant="h3" className="fs-14 fw-400">
                      $ {subscription?.price}
                    </Typography>
                  </Box>
                  {/* <Box className='d-flex justify-content-between mt-10'>
                                <Typography variant='h3' className='fs-14 fw-400'>2 bundles (50 Credits)</Typography>
                                <Typography variant='h3' className='fs-14 fw-400'>$ 100</Typography>
                            </Box> */}
                  <Box className="d-flex justify-content-between mt-20">
                    <Typography variant="h3" className="fs-14 fw-600">
                      Subtotal
                    </Typography>
                    <Typography variant="h3" className="fs-14 fw-700">
                      $ {subscription?.price}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <Card sx={{ px: 6, py: 3, borderRadius: 5 }}>
                  <CustomTabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="profile tabs"
                  >
                    <CustomTab tabIndex={0} label="Pay By Card" />
                    <CustomTab tabIndex={1} label="Google Pay" />
                    <CustomTab tabIndex={2} label="Apple Pay" />
                  </CustomTabs>
                  <Divider
                    className=" mb-40"
                    sx={{ opacity: 1 }}
                    aria-hidden="true"
                  />

                  {selectedTab === 0 && (
                    <Elements
                      stripe={stripePromise}
                      options={{ clientSecret: paymentIntent?.client_secret }}
                    >
                      <CheckoutForm />
                    </Elements>
                  )}
                  {selectedTab === 1 && (
                    <Box>
                      <Elements stripe={stripePromise}>
                        <GooglePayCheckoutForm />
                      </Elements>
                    </Box>
                  )}
                  {selectedTab === 2 && (
                    <Box>
                      <Elements stripe={stripePromise}>
                        <ApplePayCheckoutForm />
                      </Elements>
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Payment;
