import Utils from "../../utils/reduxUtils";
import { toast } from "react-toastify";
export const feedback = (value, navigate) => {
    return (dispatch) => {
      Utils.api.postApiCall(
                Utils.EndPoint.contactUs,
                value,
                (resData) => {
                    if (resData?.status === 201) {
                        toast.success(resData?.data?.message, {
                            autoClose: 2000,
                        });
                    } else {
                        toast.error(resData?.data?.message, {
                            autoClose: 2000,
                        })
                    }
                },
                (error) => {
                    toast.error(error?.data?.message, {
                        autoClose: 2000,
                    });
                }
            );
    };
};