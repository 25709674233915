import Utils from "../../utils/reduxUtils";

const {SET_LANGUAGE}=Utils.ActionName

const initialState={
    selectedLanguage:'en'
}


export const languageReducer=(state=initialState,action)=>{
   switch(action.type){
      case SET_LANGUAGE:
        return {
        ...state,
        selectedLanguage:action.payload
        };
      default:
        return state;  
   }
}