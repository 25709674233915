import { Box, Button, Dialog, DialogTitle, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './popup.scss'
import start from "../assets/img/start.jfif"
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router';
const Start = ({ visible, setVisible }) => {
    const userToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const handleClose = () => {
        setVisible(false);
    };
    return (
        <Dialog onClose={handleClose} open={visible} className='startpopup'>
            <CancelIcon className="close" onClick={handleClose} />
            {/* <DialogTitle>Set backup account</DialogTitle> */}
            <Box className="d-flex">
                <Box className="content">
                
                <Typography variant='h2' className='text-black fs-40 fw-400 mb-25'><span className='text-orange fw-500'>Ready to Revolutionize Recruitment?</span>  </Typography>
                    <Typography variant='h2' className='text-black fs-22 fw-400 mb-25'>Say goodbye to boring, ineffective job postings. With IQ-Hire, you’ll create scientifically optimized, engaging texts in seconds – and attract the best candidates effortlessly. <span className='text-orange fw-500'>Click below to try our AI-powered chatbot or explore the solution now!</span>  </Typography>
                    {/* <Typography variant='h4' className='text-black fs-24 fw-500 mb-35'>Say goodbye to boring, ineffective job postings. With IQ-Hire, you’ll create scientifically optimized, engaging texts in seconds – and attract the best candidates effortlessly.</Typography> */}
                    <Button
                        onClick={() => {
                            handleClose();
                            {
                                userToken &&
                                navigate('/chatbot-update');
                            }
                        }}
                        className="primary-btn"
                        sx={{ width: '144px' }}
                    >
                        Start
                    </Button>

                </Box>
                <Box className="img">
                    <img src={start} />
                </Box>
            </Box>
        </Dialog>
    )
}

export default Start
