import { Box, Grid, Typography, List, ListItem  } from '@mui/material'
import React, { useEffect } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
const ServiceTerms = () => {
  useEffect(()=>{
   window.scrollTo(0,0);
  },[])
  return (
    <Box>
    <Header></Header>
    <Box className="mb-20">
      <Grid container className='px-5'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant='h1' className='fs-35 fw-500 text-center mb-20 mt-20'>Terms Of Service</Typography>
          <Typography variant='h3' className='fs-15 fw-500 mt-10'><b>Effective Date:</b> 01/01/2025</Typography>
          <Typography variant='h3' className='fs-15 fw-500 mb-20'><b>Last Updated:</b> 01/01/2025</Typography>
          <Typography variant='h3' className='fs-20 fw-400'>Welcome to  <b>IQ-Hire! </b> IQ-Hire! By accessing our website and services, you agree to these Terms of Service. Please read them carefully.</Typography>
       

       {/* 1. Introduction */}

          <Box className="mt-20">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>1. Introduction</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>These Terms govern your use of IQ-Hire’s platform and services. By creating an account or subscribing, you accept these Terms.</Typography>
          </Box>

        {/* 2. Services*/}
          <Box className="mt-20">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>2. Services</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>IQ-Hire provides::</Typography>
            <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                AI-driven tools for generating job postings.
                </Typography>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Solutions tailored to individual recruiters, recruitment agencies, and corporate HR departments.
                </Typography>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Optional subscription plans for advanced features.
                </Typography>
              </ListItem>
            </List>
          </Box>


          {/*3. User Responsibilities */}
          <Box className="mt-20">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>3. User Responsibilities</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>As a user, you agree to:</Typography>
            <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Provide accurate and up-to-date information when registering.
                </Typography>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Use the platform for lawful recruitment purposes only.
                </Typography>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Refrain from uploading or sharing illegal, harmful, or misleading content.
                </Typography>
              </ListItem>
            </List>
          </Box>


             {/* 4. Subscription and Billing */}
             <Box className="mt-20">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>4. Subscription and Billing</Typography>
            {/* <Typography variant='h4' className='fs-20 mb-20'>As an individual, you have the following rights under GDPR:</Typography> */}
            <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Subscriptions are offered on a monthly or annual basis.
                </Typography>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                Payments must be made in euros (€) and are non-refundable unless stated otherwise.
                </Typography>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                <Typography variant="body1">
                We reserve the right to update pricing with prior notice.
                </Typography>
              </ListItem>
            </List>
            {/* <Typography>You may exercise your rights by contacting us at contact@iq-hire.com.</Typography> */}
          </Box>
       
          {/*5. Intellectual Property  */}
         <Box className="mt-20">
         <Typography variant='h1' className='fs-25 fw-400 mb-10'>5. Intellectual Property</Typography>
          <Typography variant='h3' className='fs-17 fw-400'>All content, software, and materials provided by IQ-Hire are protected by intellectual property laws. You are granted a limited license to use the platform for its intended purposes.</Typography>
        </Box>

       
        {/* 6. Data Protection*/}
        <Box className="mt-20">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>6. Data Protection</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>We comply with GDPR and Dutch privacy regulations. For details on how we process your data, refer to our Privacy Statement.</Typography>
        
            {/* <Typography>You can manage or disable cookies through your browser settings.</Typography> */}
          </Box>

            {/* 7. Termination */}
          <Box className="mt-20">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>7. Termination</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>We reserve the right to suspend or terminate your account if you violate these Terms. Users may cancel their subscription at any time by contacting support.</Typography>
          </Box>

              {/* 8. Limitation of Liability */}
              <Box className="mt-20 ">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>8. Limitation of Liability</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>To the fullest extent permitted by Dutch law, IQ-Hire is not liable for indirect or incidental damages resulting from your use of the platform.</Typography>
          </Box>
          
            {/* 9. Governing Law and Dispute Resolution*/}
            <Box className="mt-20 ">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>9. Governing Law and Dispute Resolution</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>These Terms are governed by the laws of the Netherlands. Disputes will be resolved in accordance with Dutch law through mediation or arbitration.</Typography>
          </Box>

            {/* 10. Changes to These Terms */}
            <Box className="mt-20 ">
            <Typography variant='h1' className='fs-25 fw-400 mb-10'>10. Changes to These Terms</Typography>
            <Typography variant='h4' className='fs-20 mb-20'>We may update these Terms from time to time. Continued use of our services constitutes acceptance of the updated Terms.</Typography>
          </Box>
          <Typography variant='h4' className='fs-20 mb-20'>For inquiries regarding these Terms, contact us at: 
                       <b style={{fontWeight:600}}>contact@iq-hire.com</b> </Typography>
        </Grid>
      </Grid>
    </Box>
    <Footer></Footer>

  </Box>
    // <Box>
    //     <Header></Header>
    //     <Box>
    //         <Grid container className='px-5'>
    //             <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    //                 <Typography variant='h1' className='fs-37 fw-700 text-center'>Terms of Service </Typography>
    //                 <Typography variant='h4' className='fs-15 fw-400 text-center px-5 mt-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </Typography>

    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Amet minim mdsdso</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium f</Typography>

    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llit non deserunt u</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magum.</Typography>
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Ua dolor do amet sint</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Integer id augue iaculis, iaculis orci ut, blandit quam. Donec in elit auctor, finibus quam in, phar</Typography>
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Equat duis enim velit</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus bl</Typography>
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Mollit. Exercitastion</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula </Typography>
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Mollit. Exercitastion</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Nibh tellus molestie nunc non blandit massa enim. Facilisis magna etiam tempor orci eu lobortis elem</Typography>
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Ua dolor do amet sint</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Aenean posuere massa pellentesque sagittis ultricies. Curabitur tristique magna at enim varius, quis</Typography>

    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Veniam consequsat</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Nibh tellus molestie nunc non blandit massa enim. Facilisis magna etiam tempor orci eu lobortis elem</Typography>
                
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llamco est sit alissq</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ex enim, euismod non consequat a, eg</Typography>    
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llit non deserunt u</Typography>
    //                 <Typography variant='h3' className='fs-15 fw-400 mb-30'>Aenean sed dictum lacus. Aenean id felis metus. Maecenas mollis sem vitae elit fringilla, quis dictu</Typography>
                    
    //                 <Typography variant='h2' className='fs-22 fw-800 mb-20'>Velit officia conddss</Typography>

    //             </Grid>
    //         </Grid>
    //     </Box>
    //     <Footer></Footer>

    // </Box>
  )
}

export default ServiceTerms
