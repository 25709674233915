import Utils from "../../utils/reduxUtils";

export const getDashboardData = (params) => {
    return (dispatch) => {
        dispatch({type:Utils.ActionName.DASHBOARD_DATA_LOADING})
            Utils.api.getApiCall(
                Utils.EndPoint.getDashboardData(params),
                (resData) => { // Success callback
                    if (resData?.status === 200) {
                       
                        dispatch({ type: Utils.ActionName.GET_DASHBOARD_DATA, payload: resData.data });
                        //  dispatch({type:Utils.ActionName.VACANCYNUMBER, payload:resData.data.dashboard.vacancyTextGenerated})
                        
                    } else {
                        dispatch({ type: Utils.ActionName.RESET_DASHBOARD_DATA, payload: resData.data });
                        
                    }
                },
                (error) => { 
                    // reject(error); 
                },
                false, 
                "" 
            );
    };
};

// xyz(diapatch)
