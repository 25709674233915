import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'es', 'fr','nl', 'ar','de'],
    fallbackLng: 'en',
    debug: true,
    defaultNS:"Home",
    ns:["Home","Footer","Header","OurWork","Pricing"],
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}/translation.json',
    },
  })
  .then(() => console.log('i18n initialized')) // Add this log
  .catch((err) => console.error('i18n initialization error:', err));

export default i18n;

