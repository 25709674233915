import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import  {thunk}  from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authenticationReducer } from "./reducer/authReducer";
import { jobDescriptionFileUploadReducer } from "./reducer/chatbotReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { profileReducer } from './reducer/profileReducer';
import { subscriptionReducer } from './reducer/subscriptionReducer';
import { dashboardReducer } from './reducer/dashboardReducer';
import { chatbotReducers } from './reducer/chatbotReducers';
import { languageReducer } from './reducer/languageReducer';
import { clientInformationReducer } from './reducer/clientInformationReducer';
import {profileImageReducer} from './reducer/profileImageUploadReducer';


const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    authenticationReducer: authenticationReducer,
    profileReducer:profileReducer,
    jobDescriptionFileUploadReducer:jobDescriptionFileUploadReducer,
    subscriptions: subscriptionReducer,
    dashboard: dashboardReducer,
    newChatbot:chatbotReducers,
    language:languageReducer,
    clientInformation:clientInformationReducer,
    profileImageReducer:profileImageReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const store = createStore(persistedReducer,applyMiddleware(...middleware));
const persistor = persistStore(store);

export { store, persistor };