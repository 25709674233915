import { Box, Grid, Typography, List, ListItem } from '@mui/material'
import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'

const PrivacyTerm = () => {
  return (
    <Box>
      <Header></Header>
      <Box className="mb-20">
        <Grid container className='px-5'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
             <Typography variant='h1' className='fs-35 fw-500 text-center mb-20 mt-20'>Privacy Statement</Typography>
            <Typography variant='h3' className='fs-15 fw-500 mt-10'><b>Effective Date:</b> 01/01/2025</Typography>
            <Typography variant='h3' className='fs-15 fw-500 mb-20'><b>Last Updated:</b> 01/01/2025</Typography>
            <Typography variant='h3' className='fs-20 fw-400'>At <b style={{ fontWeight: "600" }}>IQ-Hire</b> , we value your privacy and are committed to protecting your personal data in compliance with the <b style={{ fontWeight: "600" }}>General Data Protection Regulation (GDPR)</b>  and Dutch privacy laws. This Privacy Statement explains how we collect, use, and protect your information when you use our services.</Typography>
         
         {/* Data We Collect */}

            <Box className="mt-20">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>1. Data We Collect</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>We collect and process the following categories of personal data:</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    Identification Data: Name, email address, phone number.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    Recruitment Data: Job postings, organizational preferences, and related recruitment information.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    Payment Information: Billing details, subscription plans, and payment methods.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    Usage Data: IP addresses, browser type, and activity logs on our platform.
                  </Typography>
                </ListItem>
              </List>
            </Box>

          {/* How We Use Your Data */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>2. How We Use Your Data</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>Your data is processed for the following purposes:</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  To provide and improve our services.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  To generate personalized job postings and recruitment content.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  To communicate with you about updates, features, or promotional offers.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  To comply with legal obligations under Dutch law.
                  </Typography>
                </ListItem>
              </List>
            </Box>


            {/* Data Sharing */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>3. Data Sharing</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>We only share personal data under the following circumstances:</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Service Providers: With trusted partners, such as payment processors, operating under strict confidentiality agreements.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Legal Requirements: When required by Dutch or European law or to protect our legal interests.
                  </Typography>
                </ListItem>
              </List>
            </Box>


               {/* How We Use Your Data */}
               <Box className="mt-20">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>4. Your Rights</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>As an individual, you have the following rights under GDPR:</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Access and Rectification: Request a copy of your data or correct inaccuracies.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Erasure: Request deletion of your data (“Right to be Forgotten”), subject to legal obligations.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Data Portability: Receive your data in a structured, commonly used format.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Restriction and Objection: Restrict or object to certain types of processing.
                  </Typography>
                </ListItem>
              </List>
              <Typography>You may exercise your rights by contacting us at contact@iq-hire.com.</Typography>
            </Box>
         
            {/*Data Retention  */}
           <Box className="mt-20">
           <Typography variant='h1' className='fs-25 fw-400 mb-10'>5. Data Retention</Typography>
            <Typography variant='h3' className='fs-17 fw-400'>We retain personal data for as long as necessary to fulfill the purposes outlined above or as required by Dutch law.</Typography>
          </Box>

         
          {/* Cookies and Tracking Technologies*/}
          <Box className="mt-20">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>6. Cookies and Tracking Technologies</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>Our platform uses cookies and similar technologies to:</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Enhance functionality and improve user experience.
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  Track performance and usage statistics.
                  </Typography>
                </ListItem>
              </List>
              <Typography>You can manage or disable cookies through your browser settings.</Typography>
            </Box>

              {/* Security Measures */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>7. Security Measures</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>We implement industry-standard measures, including encryption and secure servers, to protect your data against unauthorized access or disclosure.</Typography>
            </Box>

                {/* Changes to This Privacy Statement */}
                <Box className="mt-20 ">
              <Typography variant='h1' className='fs-25 fw-400 mb-10'>8. Changes to This Privacy Statement</Typography>
              <Typography variant='h4' className='fs-20 mb-20'>We may update this Privacy Statement periodically to reflect changes in our operations or legal requirements. Significant changes will be communicated to you directly.</Typography>
            </Box>
            
            <Typography variant='h4' className='fs-20 mb-20'>For any questions or concerns, please contact our Data Protection Officer (DPO) at: 
             <b style={{fontWeight:600}}>contact@iq-hire.com</b> </Typography>

            {/* <Typography variant='h2' className='fs-22 fw-800 mb-20'>Amet minim mdsdso</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium f</Typography>

                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llit non deserunt u</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magum.</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Ua dolor do amet sint</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Integer id augue iaculis, iaculis orci ut, blandit quam. Donec in elit auctor, finibus quam in, phar</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Equat duis enim velit</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus bl</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Mollit. Exercitastion</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula </Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Mollit. Exercitastion</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Nibh tellus molestie nunc non blandit massa enim. Facilisis magna etiam tempor orci eu lobortis elem</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Ua dolor do amet sint</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Aenean posuere massa pellentesque sagittis ultricies. Curabitur tristique magna at enim varius, quis</Typography>

                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Veniam consequsat</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Nibh tellus molestie nunc non blandit massa enim. Facilisis magna etiam tempor orci eu lobortis elem</Typography>
                
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llamco est sit alissq</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ex enim, euismod non consequat a, eg</Typography>    
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llit non deserunt u</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Aenean sed dictum lacus. Aenean id felis metus. Maecenas mollis sem vitae elit fringilla, quis dictu</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Velit officia conddss</Typography> */}

          </Grid>
        </Grid>
      </Box>
      <Footer></Footer>

    </Box>
  )
}

export default PrivacyTerm
