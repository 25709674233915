import Utils from "../../utils/reduxUtils";

const {UPLOAD_IMAGE_REQUEST,UPLOAD_IMAGE_SUCCESS,UPLOAD_IMAGE_FAILURE}=Utils.ActionName;

const initialState={
    loading:false,
    image:null,
    error:null
}

export const profileImageReducer=(state=initialState,action)=>{
   
    switch(action.type){
   
    case UPLOAD_IMAGE_REQUEST:
      return {...state,loading:true,error:null};

    case UPLOAD_IMAGE_SUCCESS:
      return {...state,image:action.payload,loading:false};  

    case UPLOAD_IMAGE_FAILURE:
      return {...state,loading:false,error:action.payload};
    default:
        return state;  
       
   }
}
