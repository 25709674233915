import Utils from "../../utils/reduxUtils";

export const getSubscriptions = () => {
    return (dispatch) => {
        // return new Promise((resolve, reject) => {
            Utils.api.getApiCall(
                Utils.EndPoint.getSubscriptions,
                (resData) => {
                    dispatch({
                        type: Utils.ActionName.GET_SUBSCRIPTIONS,
                        payload: resData,
                    });
                    // resolve(resData);
                },
                (error) => {
                    // reject(error);
                }
            );
        // });
    };
}

export const getSubscriptionById = (id) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            Utils.api.getApiCall(
                Utils.EndPoint.getSubscriptionById(id),
                (resData) => {
                    dispatch({
                        type: Utils.ActionName.GET_SUBSCRIPTIONS_BY_ID,
                        payload: resData,
                    });
                    resolve(resData);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    };
}

export const generatePaymentIntent = (subscriptionId, userId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            Utils.api.postApiCall(
                Utils.EndPoint.generatePaymentIntent,
                {subscriptionId, userId},
                (resData) => {
                    dispatch({
                        type: Utils.ActionName.GENERATE_PAYMENT_INTENT,
                        payload: resData,
                    });
                    resolve(resData);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    };
}

export const paymentSucceded = (subscriptionId, clientSecret,intentId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            Utils.api.postApiCall(
                Utils.EndPoint.paymentSucceded,
                {subscriptionId, intentId, clientSecret},
                (resData) => {
                    dispatch({
                        type: Utils.ActionName.PAYMENT_SUCCEDED,
                        payload: resData,
                    });
                    resolve(resData);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    };
}

export const getAllSubscriptions = () => {
    return (dispatch) => {
        // return new Promise((resolve, reject) => {
            Utils.api.getAllSubscription(
                Utils.EndPoint.getAllSubscriptions,
                (resData) => {
                    dispatch({
                        type: Utils.ActionName.GET_SUBSCRIPTIONS,
                        payload: resData,
                    });
    
                 },
                (error) => {
                    let errorMessage = "Try Again Later";
                    const errorData = error.message ? error.message : null;

                    if (error?.statusCode === 404) {
                        errorMessage = "User Not Found";
                    } else if (error?.statusCode === 401) {
                        errorMessage = "Invalid credentials";
                    } else if (errorData && errorData.message) {
                        errorMessage = errorData.message;
                    }
                    // toast.error(errorMessage, {
                    //     autoClose: 2000,
                    // });
                    // reject(error); 
                }
            );
        // });
    };
};
