import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/img/logo.svg';
import { FormControl, Select } from '@mui/material';
import company from '../assets/img/company.png';
import downarrow from '../assets/img/downarrow.png';
import global from '../assets/img/global.png';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNavigate } from "react-router";
import { useDispatch, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import '../assets/styles/header.scss';
import { chatbotData } from '../redux/action/chatbotAction';
import { setLanguage } from '../redux/action/languageAction';
import GoogleTranslateWidget from './GoogleTranslateWidget';
import i18n from '../utils/i18n';
import { useTranslation } from 'react-i18next';



const settings = [
  { label: 'My Profile', action: 'profile' },
  { label: 'Dashboard', action: 'dashboard' },
  { label: 'Logout', action: 'logout' }
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const userToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const userData = useSelector(state => state?.authenticationReducer);
  console.log(userData,"userData");
  
  const [age, setAge] = useState(1);
  // const [selectedLanguage,setSelectedLanguage]=useState("English");
  const [loading,setLoading]=useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(userToken ? true : false);
  const dispatch=useDispatch();
  const sessionId = useSelector(state => state?.newChatbot?.newSession?._id);
  const selectedLanguage=useSelector((state)=>state.language.selectedLanguage);
  const { t } = useTranslation("Header");
  console.log("selected25Language",selectedLanguage);
  
  const endPoint=window.location.pathname;
  console.log("end55Point",endPoint);
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const pages = [
    { label: t('home'), path: '/' },
    { label: t('who_we_are'), path: '/ourwork' },
    { label: t('services'), path: '/services' },
    { label: t('pricing'), path: '/pricing' }
  ];
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChange = async(event) => {
  const updatedLanguage=event.target.value;
   if(endPoint==="/job/vacancies"){
      dispatch(setLanguage(updatedLanguage));
      const payload = { sessionId: sessionId,language:updatedLanguage}
      await dispatch(chatbotData(payload,setLoading,navigate));
      return;
      }
    // Update i18n language
  if(updatedLanguage==="ar"){
    document.documentElement.setAttribute('dir','rtl');
  }
  else{
    document.documentElement.setAttribute('dir','ltr');
  }

  i18n.changeLanguage(updatedLanguage.toLowerCase());
  dispatch(setLanguage(updatedLanguage));
 

  };

  const handleNavigate = (path) => {
    console.log(path,"path");
    navigate(path);
    handleCloseNavMenu();
  };

  const handleUserMenuClick = (action) => {
    handleCloseUserMenu();
    console.log(action);
    switch (action) {
      case 'logout':
        localStorage.clear();
        toast.dismiss();
        toast.success('Logged out successfully');
    
        setIsLoggedIn(false);
        navigate('/');
        break;
      // Add cases for other settings actions if needed
      case 'profile':
        navigate('/profile');
        break;
      case 'dashboard':
        navigate('/dashboard');
        break;
      default:
        break;
    }
  };

// useEffect(()=>{
//   console.log('endpoint changed');
//   if(endPoint!=="job/vacancies"){
// <GoogleTranslateWidget/>
// }
// },[endPoint])

  console.log("location",window.location)

  return (<>
  {/* <GoogleTranslateWidget/> */}
  <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters className='no-hover-bg'>
          <Link to={"/"}><img width={172} height={66} src={logo} alt="Logo" /></Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={() => handleNavigate(page.path)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },marginLeft:{lg:"70px"},gap:"37px" }}>
            {pages.map((page) => (
              <Button
              className='fw-500 fs-18 p-0 text-capitalize'
                key={page.label}
                onClick={() => handleNavigate(page.path)}
                sx={{ }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          {/* <Box className='d-flex mr-10'>
            {isLoggedIn && endPoint!=="/job/vacancies"?
               <GoogleTranslateWidget/>
              
              :
              isLoggedIn && endPoint!=="/job/vacancies" && <Button className='secondary-btn my-auto' sx={{ minWidth: 'fit-content' }} onClick={() => navigate('/signin')}>Login</Button>
            }
          </Box> */}

          <Box className='d-flex mr-10'>
            {isLoggedIn && !endPoint.includes('sharejob')?
              <>
                <img className='my-auto' width={27} height={27} src={global} alt="Global" />
                <Box sx={{ flexGrow: 0, width: '100px' }}>
                  <FormControl fullWidth>
                    <Select
                      className='no-fieldset-border'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedLanguage}
                      onChange={handleChange}
                    >
                      <MenuItem value={"en"}><Typography className='fs-14 fw-600'>English</Typography></MenuItem>
                      <MenuItem value={'nl'}><Typography className='fs-14 fw-600'>Dutch</Typography></MenuItem>
                      <MenuItem value={"ar"}><Typography className='fs-14 fw-600'>Arabic</Typography></MenuItem>
                      <MenuItem value={"fr"}><Typography className='fs-14 fw-600'>French</Typography></MenuItem>
                      <MenuItem value={"de"}><Typography className='fs-14 fw-600'>German</Typography></MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Button className='primary-btn my-auto' sx={{ minWidth: 'fit-content', height: '34px', width: '34px' }}>
                  <NotificationsNoneIcon sx={{ fill: 'white !important', fontSize: '18px' }} />
                </Button>
              </>
              :
              !isLoggedIn && <Button  className='secondary-btn' sx={{
                minWidth: 'fit-content'
              }} onClick={() => navigate('/signin')}>Login</Button>
            }
          </Box>
         
          <Box sx={{ flexGrow: 0 }}>
            {isLoggedIn ?
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <img src={company} alt="Company" />
                    <Typography className='fs-14 fw-600'>{userData?.fullName}</Typography>
                    <img src={downarrow} alt="Down Arrow" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.label} onClick={() => handleUserMenuClick(setting.action)}>
                      <Typography textAlign="center">{setting.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
              :
              <Button className='signUp-btn my-auto' sx={{ minWidth: 'fit-content' }} onClick={() => navigate('/signup')}>Sign Up</Button>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  </>
    
  );
}

export default Header;