import Utils from "../../utils/reduxUtils";

const {GET_CLIENTINFO_REQUEST,GET_CLIENTINFO_SUCCESS,GET_CLIENTINFO_FAILURE}=Utils.ActionName;


const initialState={
    loading:false,
    data:[],
    error:null
}

export const clientInformationReducer=(state=initialState,action)=>{
   switch(action.type){
    
    case GET_CLIENTINFO_REQUEST:
        return {...state,loading:true};

    case GET_CLIENTINFO_SUCCESS:
        return {...state,data:action.payload};
        
    case GET_CLIENTINFO_FAILURE:
        return {...state,error:action.payload}   
    
    default: 
        return state;     
   }
}