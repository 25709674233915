import Utils from "../../utils/reduxUtils";
import axios from 'axios';

//Client information action creator
export const getClientInformation=()=>{
return async (dispatch)=>{
    dispatch({type:Utils.ActionName.GET_CLIENTINFO_REQUEST})
       try{
         const response =await axios.get('https://jsonplaceholder.typicode.com/posts');
         dispatch({type:Utils.ActionName.GET_CLIENTINFO_SUCCESS,payload:response.data})
       }
       catch(error){
         dispatch({type:Utils.ActionName.GET_CLIENTINFO_FAILURE,payload:error.message})
       }
}
}